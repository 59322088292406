<template>
  <v-row justify="center">
    <v-col cols="11" :md="pi ? 4 : 5">
      <v-row
        justify="center"
        align="center"
        style="
          border-radius: 10px;
          border-color: var(--v-primary-base) !important;
          border-width: 1px;
          border-style: solid;
        "
        class="gris2"
      >
        <template v-if="!alreadyPayed">
          <v-col cols="12">
            <h2
              v-if="error"
              style="font-weigth: normal"
              class="primary--text text-center"
            >
              Error al generar el pago
            </h2>
            <h2
              style="font-weight: normal"
              class="
                primary--text
                text-center text-uppercase text-decoration-underline
              "
              v-else
            >
              {{ this.appointment_id ? "Paga y señal" : this.concepto }}
            </h2>
          </v-col>
          <v-col cols="12" md="4" v-if="!pi">
            <v-row>
              <v-col
                cols="12"
                style="border-bottom: 1px solid var(--v-primary-base)"
                class="py-0"
              >
                <h4 class="primary--text">Información de facturación</h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <label v-text="$t('first_name')"></label>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="payment_info.first_name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <label v-text="$t('last_name')"></label>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="payment_info.last_name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <label v-text="$t('email')"></label>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="payment_info.email"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <label v-text="$t('dni')"></label>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="payment_info.dni"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row justify="center">
              <v-col cols="8">
                <v-simple-table class="gris2">
                  <tr>
                    <td>PRECIO</td>
                    <td>{{ $n(price, "currency") }}</td>
                  </tr>
                  <tr>
                    <td>DESCUENTO</td>
                    <td>- {{ $n(discount_price, "currency") }}</td>
                  </tr>
                  <tr>
                    <td>TOTAL</td>
                    <td>{{ $n(final_price, "currency") }}</td>
                  </tr>
                </v-simple-table>
              </v-col>
              <v-col cols="8" v-show="!loading">
                <div
                  ref="card"
                  style="
                    border: 1px solid var(--v-primary-base);
                    padding: 7px;
                    border-radius: 60px;
                  "
                ></div>
              </v-col>
              <v-col cols="6">
                <v-btn
                  elevation="0"
                  style="height: 30px"
                  block
                  @click="processPayment"
                  :loading="loading"
                >
                  Pagar
                </v-btn>
              </v-col>
              <v-col cols="8">
                <v-row justify="center">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      outlined
                      dense
                      v-model="code"
                      hide-details
                      :placeholder="$t('discounts.code')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-btn
                      elevation="0"
                      style="height: 30px"
                      @click="apply"
                      block
                    >
                      {{ $t("discounts.apply") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <v-col v-else
          ><h2
            style="font-weight: normal"
            class="
              primary--text
              text-center text-uppercase text-decoration-underline
            "
          >
            YA SE HA PAGADO
          </h2></v-col
        >
      </v-row>
      <v-row justify="center">
        <v-col cols="3">
          <img src="@/assets/pago-seguro-garantizado.png" width="100%" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: [
    "appointment_id",
    "expense_id",
    "pi",
    "appointment_token_id",
    "appointment_date_id",
  ],
  data() {
    return {
      price: null,
      loading: false,
      alreadyPayed: false,
      paymentIntent: "stripe_secret_paymentintent",
      appointmentToken: [],
      stripe: null,
      cardElement: null,
      concepto: "",
      code: null,
      error: false,
      payment_info: {
        first_name: null,
        last_name: null,
        email: null,
        dni: null,
        discount: null,
      },
    };
  },
  mounted() {
    this.fetchTattooer();
    this.fetchPayment();
    this.fetchAppointmentTokenPayment();
    if (this.expense_id || this.appointment_id || this.pi) this.loadPayment();
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    final_price() {
      return this.price - this.discount_price;
    },
    discount_price() {
      if (this.payment_info.discount) {
        let d = this.payment_info.discount;
        if (d.type === "percent") {
          return (this.price * d.discount) / 100;
        } else return d.discount;
      }
      return 0;
    },
  },
  methods: {
    loadPayment() {
      this.$nextTick(() => {
        let stripeElement = document.createElement("script");

        stripeElement.onload = () => {
          this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

          let elements = this.stripe.elements();
          this.cardElement = elements.create("card");

          this.cardElement.mount(this.$refs.card);
        };
        stripeElement.src = "https://js.stripe.com/v3/";
        document.head.appendChild(stripeElement);
      });
    },
    ...mapActions("discounts", ["applyDiscount"]),
    apply() {
      this.applyDiscount({
        code: this.code,
        studio_id: 3,
      }).then((data) => {
        if (data.success) {
          this.payment_info.discount = data.discount;
        } else {
          this.$alert("discounts.errors." + data.motive);
        }
      });
    },
    ...mapActions("tattooers", ["getTattooerByToken"]),
    ...mapActions("appointments", [
      "getPayment",
      "getAppointment",
      "getAppointmentTokenPayment",
    ]),
    ...mapActions("expenses", ["getExpense", "getExpenseToPay"]),
    ...mapActions("inscriptions", ["getReservationPayment"]),
    load(res) {
      this.getReservationPayment(res.reserva_id).then((response) => {
        if (response.success) {
          this.loading = false;
          this.paymentIntent = response.pi;
          this.price = response.quantity;
          this.concepto = "Pago reserva";
          this.loadPayment();
        } else {
          this.$store.commit(
            "app/SET_NOTIFICATION",
            {
              message: response.data,
              show: true,
              color: "error",
              timeout: 30000,
            },
            { root: true }
          );
        }
      });
    },
    fetchTattooer() {
      if (this.appointment_id)
        this.getTattooerByToken(this.$route.params.token).then((response) => {
          this.tattooer = response;
        });
    },

    fetchAppointmentTokenPayment() {},
    fetchPayment() {
      this.loading = true;
      if (this.appointment_id)
        this.getAppointment(this.appointment_id).then((response) => {
          this.loading = false;
          console.log(response);
          this.payment_info.first_name = response.customer.user.first_name;
          this.payment_info.last_name = response.customer.user.last_name;
          this.payment_info.email = response.customer.user.email;
          this.payment_info.dni = response.customer.user.dni;

          //SI TINC APPOINTMENT TOKEN ID LOAD ACTUAL
          if (this.appointment_token_id) {
            this.getAppointmentTokenPayment(this.appointment_token_id).then(
              (response) => {
                if (response) {
                  console.log(response);
                  if (response.payed) this.alreadyPayed = true;
                  this.price = response.price;
                }
              }
            );
          } else {
            let ad = response.available_dates.find(
              (i) => i.id == this.appointment_date_id
            );
            this.price = ad.sesion_price - ad.alreadyPayed;
            console.log(this.price);
          }
        });
      else if (this.expense_id) {
        this.getExpense(this.$route.params.id).then((result) => {
          this.loading = false;
          this.concepto = result.conceptos;
          this.price = result.cantidad;
          console.log(result);
          if (result.customer) {
            this.payment_info.first_name = result.customer.user.first_name;
            this.payment_info.last_name = result.customer.user.last_name;
            this.payment_info.email = result.customer.user.email;
            this.payment_info.dni = result.customer.user.dni;
          }
        });
      } else if (this.pi) {
        this.loading = false;
        this.paymentIntent = this.pi.client_secret;
        this.price = this.pi.amount;
        this.concepto = this.pi.concept;
      }
    },
    processPayment() {
      if (this.appointment_id)
        this.getPayment({
          appointment_id: this.appointment_id,
          appointment_token_id:
            this.appointment_token_id || this.appointment_date_id,
          form: {
            ...this.payment_info,
            type: this.appointment_token_id ? "token" : "date",
          },
        }).then((response) => {
          if (response.success) {
            this.loading = false;
            this.paymentIntent = response.pi;
            this.pay();
          } else {
            this.loading = false;
            this.error = true;
            this.$alert(response.data);
          }
        });
      else if (this.expense_id) {
        this.getExpenseToPay({
          id: this.expense_id,
          form: this.payment_info,
        }).then((response) => {
          if (response.success) {
            this.loading = false;
            this.paymentIntent = response.pi;
            this.pay();
          } else {
            this.loading = false;
            this.error = true;
            this.$alert(response.data);
          }
        });
      } else if (this.pi) {
        this.pay();
      }
    },
    pay() {
      this.$store.commit(
        "app/SET_PROCESSING",
        {
          show: true,
          message: null,
        },
        {
          root: true,
        }
      );
      let card = {
        payment_method: {
          card: this.cardElement,
        },
        save_payment_method: true,
        setup_future_usage: "off_session",
      };

      this.stripe
        .confirmCardPayment(this.paymentIntent, card)
        .then((result) => {
          this.$store.commit(
            "app/SET_PROCESSING",
            {
              show: false,
              message: null,
            },
            {
              root: true,
            }
          );
          if (result.error) {
            this.$alert(result.error.message);
          } else {
            if (this.appointment_id) {
              this.$router.back();
              this.$alert("Pago realizado correctamente!");
            } else if (this.expense_id);
            this.$emit("payed");
          }
        });
    },
  },
};
</script>
<style lang="sass" scoped>
label
  height: 25px
  display: block
</style>
<style lang="sass">
.CardField
  background-color: red
</style>
